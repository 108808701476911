import { useRouter } from 'next/router'
import { usePushSubscriptionsCreate, usePushSubscriptionsUserSubscriptionsRetrieve } from '@/api/generated/hooks'
import { SubscriptionTypeEnum, UserPushSubscriptionCreate400ExceptionCodeEnum } from '@/api/generated/types'
import { QueryKeys, Routes } from '@/constants/routes'

const useSubscribePushNotification = () => {
  const { data: subscriptionList, refetch: refetchPushSubscriptionList } =
    usePushSubscriptionsUserSubscriptionsRetrieve()
  const { replace } = useRouter()

  const { mutate: createPushSubscriptions, isLoading: isCreatePushSubscriptionsLoading } = usePushSubscriptionsCreate({
    mutation: {
      onSuccess: () => {
        refetchPushSubscriptionList()
        replace({
          pathname: Routes.Home,
          query: {
            [QueryKeys.OrderNotificationSubscriptionSuccess]: true
          }
        })
      },
      onError: (e) => {
        if (e.code === UserPushSubscriptionCreate400ExceptionCodeEnum.USER_PUSH_SUBSCRIPTION_ALREADY_EXISTS) {
          refetchPushSubscriptionList()
          replace({
            pathname: Routes.Home,
            query: {
              [QueryKeys.OrderNotificationSubscriptionSuccess]: true
            }
          })
        }
      }
    }
  })

  return {
    hasSubscribed: subscriptionList?.results?.includes(SubscriptionTypeEnum.SITE_NEUBIE_AVAILABLE) ?? false,
    createPushSubscriptions,
    isCreatePushSubscriptionsLoading
  }
}

export default useSubscribePushNotification
