import { useTranslation } from 'next-i18next'
import { NodeRes, SiteRes } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import DropDown, { searchTextHighLight } from '@/components/common/design-system/DropDown'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { BottomSheetControlsLegacyType } from '@/hooks/common/useBottomSheetLegacy'
import { DropDownPropsType } from '@/hooks/common/useDropDown'
import { isInApp } from '@/pages/_app'

export type SiteMapBottomSheetDataType = {
  site?: SiteRes
  nodeList?: NodeRes[]
}

type SiteMapBottomSheetProps = {
  bottomSheetControls: BottomSheetControlsLegacyType<SiteMapBottomSheetDataType>
  dropDownControls: DropDownPropsType
  handleSavePromiseNode: () => void
  handleRequestCurrentLocationClick?: () => void
}

const SiteMapBottomSheet = ({
  bottomSheetControls,
  dropDownControls,
  handleSavePromiseNode,
  handleRequestCurrentLocationClick
}: SiteMapBottomSheetProps) => {
  const { t } = useTranslation([I18nNamespaceEnum.Home])

  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls}>
        {t('home:bottom_sheet.meeting_location.title')}
      </BottomSheetLegacy.Header>
      <div className="flex h-28 w-full items-center justify-center gap-4 text-warning">
        <Icon className="h-20 w-20" name={IconNamesEnum.Information} />
        <span className="body3 font-medium">{t('home:bottom_sheet.meeting_location.info')}</span>
      </div>
      <div className="h-8 w-full" />
      <div className="relative h-full w-full bg-gray-300">
        <div id="map" className="h-full w-full" />
        {isInApp() && (
          // todo DS에 동그란 버튼이 없는 관계로 임의 생성, 추후 수정 with jhg
          <div
            className="absolute bottom-10 right-10 flex h-40 w-40 items-center justify-center rounded-full border border-gray-300 bg-white"
            onClick={handleRequestCurrentLocationClick}>
            <Icon name={IconNamesEnum.Crosshair} className="h-24 w-24" />
          </div>
        )}
      </div>
      <div className="h-12 w-full" />
      <div className="px-16">
        <div className="flex w-full items-center gap-2">
          <Icon name={IconNamesEnum.LocationFilledFalse} className="h-20 w-20" />
          <span className="body2">{bottomSheetControls.bottomSheetData.site?.name}</span>
        </div>
        <div className="h-17 w-full" />
        <DropDown defaultItemText={t('home:bottom_sheet.meeting_location.title')} dropDownProps={dropDownControls}>
          <DropDown.Search
            searchPlaceHolder={t('home:bottom_sheet.meeting_location.placeholder')}
            dropDownProps={dropDownControls}
          />
          <DropDown.List>
            {dropDownControls.searchFilterList?.map((item) => {
              return (
                <DropDown.Item
                  dropDownProps={dropDownControls}
                  key={item.id}
                  itemContent={{
                    id: item.id,
                    content: <span>{searchTextHighLight(item.content, dropDownControls.searchText)}</span>
                  }}
                />
              )
            })}
          </DropDown.List>
        </DropDown>
      </div>
      <div className="h-16 w-full" />
      <CTAButton>
        <Button
          full
          theme={ButtonThemeEnum.Primary}
          size={ButtonSizeEnum.ExtraLarge}
          disabled={!dropDownControls.selectedItem}
          onClick={handleSavePromiseNode}>
          <span className="body1 font-bold">{t('home:bottom_sheet.meeting_location.cta')}</span>
        </Button>
      </CTAButton>
    </>
  )
}

export default SiteMapBottomSheet
