import { TFunction } from 'i18next'
import { useTranslation } from 'next-i18next'
import { OrderExpectWaitStatusEnum, SiteDispatchStatusRes, SubscriptionTypeEnum } from '@/api/generated/types'
import StaticImg from '@/components/common/StaticImg'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { BottomSheetControlsLegacyType } from '@/hooks/common/useBottomSheetLegacy'
import useSubscribePushNotification from '@/hooks/common/useSubscribePushNotification'

export type RobotStatusBottomSheetDataType = {
  robotStatus?: SiteDispatchStatusRes
}

type RobotStatusBottomSheetProps = {
  bottomSheetControls: BottomSheetControlsLegacyType<RobotStatusBottomSheetDataType>
}

const getOrderExpectText = (t: TFunction, orderExpectWaitStatus?: OrderExpectWaitStatusEnum) => {
  switch (orderExpectWaitStatus) {
    case OrderExpectWaitStatusEnum.WAIT:
      return {
        title: t('common:bottom_sheet.robot_status.title_wait'),
        desc: t('common:bottom_sheet.robot_status.desc_wait')
      }
    case OrderExpectWaitStatusEnum.UNKNOWN:
      return {
        title: t('common:bottom_sheet.robot_status.title_wait'),
        desc: t('common:bottom_sheet.robot_status.desc_wait')
      }
    case OrderExpectWaitStatusEnum.UNAVAILABLE:
      return {
        title: t('common:bottom_sheet.robot_status.title_unavailable'),
        desc: t('common:bottom_sheet.robot_status.desc_unavailable')
      }
    default:
      return undefined
  }
}

const isWaitRobot = (robotStatus: SiteDispatchStatusRes | undefined) => {
  return robotStatus && robotStatus.orderExpectWaitStatus === OrderExpectWaitStatusEnum.WAIT
}

const RobotStatusBottomSheet = ({ bottomSheetControls }: RobotStatusBottomSheetProps) => {
  const { t } = useTranslation([I18nNamespaceEnum.Common])
  const { bottomSheetData, handleClose } = bottomSheetControls
  const robotStatus = bottomSheetData?.robotStatus
  const { hasSubscribed, createPushSubscriptions } = useSubscribePushNotification()
  if (robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.AVAILABLE) {
    return null
  }

  const handleSubscribeClick = () => {
    if (!hasSubscribed) {
      createPushSubscriptions({
        data: {
          subscriptionType: SubscriptionTypeEnum.SITE_NEUBIE_AVAILABLE
        }
      })
    }
    handleClose()
  }

  const { title, desc } = getOrderExpectText(t, robotStatus?.orderExpectWaitStatus) || {}
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls} background="bg-gray-50">
      <div className="flex w-full flex-col items-center justify-center px-16 pt-40">
        <span className="title4 whitespace-pre-line text-center font-bold">{title}</span>
        <span className="body3 mt-8 whitespace-pre-line text-center font-medium text-gray-700">{desc}</span>
        <div className="h-12 w-full" />
        <StaticImg className="h-150 w-150" src="/images/home-robot-status.png" alt="로봇 상태 바텀시트 뉴비그림" />
      </div>
      <div className="h-30 w-full" />
      <div className="py-12 px-16">
        <Button full theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.ExtraLarge} onClick={() => handleClose()}>
          <span className="body1 font-bold">{t('common:bottom_sheet.robot_status.cta')}</span>
        </Button>
      </div>
      {!hasSubscribed && isWaitRobot(robotStatus) && (
        <div className="w-full px-16 py-6 text-center">
          <button className="text-14 underline" onClick={handleSubscribeClick}>
            {t('common:bottom_sheet.robot_status.push_notification_subscription_available')}
          </button>
        </div>
      )}
    </BottomSheetLegacy>
  )
}

export default RobotStatusBottomSheet
